.dropdown_content {
  width: 100%;
  background-color: rgb(34, 30, 30);
}

.i_icon {
  margin-right: 10px;
  padding: 0px 0px 20px 0px;
  float: right;
}

.li_content {
  padding-left: 10px !important;
}