.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F0F0F0;
  padding: 32px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 100vh;
  width: 100%;
}

.header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #333;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 80%;
}

.button {
  width: calc(50% - 8px);
  height: 40px;
  margin-bottom: 16px;
  background-color: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.button:hover {
  background-color: #0D47A1;
}