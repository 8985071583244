.tableContainer {
  max-width: 90%;
  display: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: auto; */
  margin-top: -15%;
  height: auto;
  overflow: hidden;
}

.mainTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* position: absolute; */
  margin-top: 20%;
  /* top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
  /* margin: auto; */
  height: auto;
  overflow: unset;
}

/* .tableContainer{
    max-width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    margin-top: 15%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
} */


.pageTitle {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: 2.5%;
}

.mainRowHeader {
  background-color: rgb(22, 120, 128);
  color: white;
}

.mainRowHeaderText {
  color: #fff !important;
  /* display: none; */
}

/* .mainRowTextExpanded {
    font-weight: 700;
} */

.openedRow .mainRowTextExpanded {
  background-color: #51c092;
  font-weight: 700;

}

/* .table{
    display: flex;
	flex-direction: column;
	align-items: center;
} */