.clientInformationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.clientInformationHeader {
  margin-top: 50px;
  font-size: 36px;
}

.clientInformation {
  margin-top: 50px;
  width: 50%;
  border-collapse: collapse;
  text-align: left;
}

.clientInformationRow {
  border: 1px solid gray;
}

.clientInformationTitle {
  padding: 10px 20px;
  font-weight: bold;
}

.clientInformationData {
  padding: 10px 20px;
}

@media (max-width: 600px) {
  .clientInformation {
    width: 100%;
  }
}

.deleteClientBtn {
  display: inline;
  width: 10%;
}

.clientHeader {
  display: flex;
  justify-content: space-between;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}