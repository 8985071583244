.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: middle;
}

.titleContainer {
  margin-bottom: 20px;
}

.pageTitle {
  font-size: 30px;
  /* text-align: center; */
}

.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input {
  border: 1px solid gray;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  outline: none;
  margin-right: 10px;
}

.button {
  background-color: #2a67a0;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  cursor: pointer;
}

.button:hover {
  background-color: #06233d;
  cursor: hand;
}