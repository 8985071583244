.forgotPassFormText {
  margin-top: 35px;
}

.forgotPassFormText p {
  font-size: 25px;
  font-weight: 500;
}

.inputContainer {
  margin-bottom: 10px;
}

.inputContainer label {
  font-size: 12px;
}

.submitBtn {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #0077ff;
  color: white;
  border-radius: 0.5rem;
  margin-top: 5%;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  width: 100%;
}

.backBtn {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #52ad2e;
  color: white;
  border-radius: 0.5rem;
  margin-top: 5%;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  width: 100%;
}