/* .sidebar {
		background-color: #659acf;
		height: 100vh;
		width: auto;
		position: fixed;
		top: 0;
		bottom: 0;
		overflow: scroll;
} */

/* Sidebar Main */
/* .sidebar {
  position: fixed;
  display: flex;
  top: 0px;
  bottom: 0px;
  left: 0;
  width: 18rem;
  height: 100%;
  background-color: #2f2f2f;
  flex-direction: column;
  z-index: 1000;
}

.sidebar hr {
  border: 1px solid #fff;
  margin: -15px 0;
  order: 2;
}

.sidebar_header {
  width: 100%;
  text-align: center;
  padding: 20px;
  order: 1;
  height: 20%;
  border-bottom: 1px solid rgb(139, 138, 138);
}

.sidebar_header img {
  height: 30px;
}

.sidebar_header_title {
  color: #fff;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.sidebar_content {
  word-wrap: break-word;
  height: 70%;
  display: flex;
  flex-direction: column;
  order: 3;
  color: #fff;
  padding-top: 0px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.sidebar_content h3 {
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
}

.sidebar_content ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #fff;
}

.sidebar_content li {
  margin-bottom: 10px;
  color: #fff;
  padding-left: 0;
  padding-right: 0;
}

.sidebar_content a {
  display: block;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  padding: 10px;
  width: 100%;
}

.sidebar_content li:hover {
  background-color: transparent !important;
  color: #fff;
}

.ant-menu-item-selected {
  background-color: #B039CC !important;
}

.sidebar_content .dropdown-menu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar_content .dropdown-menu li {
  margin-bottom: 10px;
}

.sidebar_content .dropdown-menu a {
  display: block;
  font-size: 14px;
  color: #333;
  text-decoration: none;
  padding: 10px 20px;
}

.sidebar_content .dropdown-menu a:hover {
  background-color: #ddd;
  color: #000;
}

.sidebar_content .dropdown-toggle::after {
  content: "\25BE";
  float: right;
  font-size: 12px;
  margin-left: 10px;
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;
}

.sidebar_content .dropdown-toggle.active::after {
  transform: rotate(-90deg);
}

.invoicing_dropdown {
  padding-left: 20px;
}

.fontawesome_icon {
  padding: 0px 10px 0px 5px;
}

nav a.active {
  background-color: lightgray;
  color: #000;
}

.sidebar_content::-webkit-scrollbar {
  width: 0.5em;
  background-color: #b3b3b4;
  scrollbar-width: thin;
}

.sidebar_content::-webkit-scrollbar-thumb {
  background-color: #555;
  display: block;
  border-radius: 20px;
}

.sidebar_content::-webkit-scrollbar-thumb:hover {
  background-color: #000;
  display: block;
  border-radius: 20px;
}

.sidebar_version {
  height: 10%;
  display: flex;
  flex-direction: column;
  order: 3;
  color: #fff;
  border-top: 1px solid rgb(139, 138, 138);
}
.sidebar_vcontent {
  top: 0px;
}
.sidebar_vcontentP1 {
  font-size: 10px;
  height: 30%;
  width: 50%;
  position: absolute;
  padding-left: 20px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.sidebar_vcontentBar {
  font-size: 15px;
  height: 100%;
  width: 50%;
  float: right;
  margin-top: 12%;
}
.sidebar_vcontent img {
  height: 25px;
  margin-top: -20px;
} */

/* Sidebar Main */
.sidebar {
  position: fixed;
  display: flex;
  top: 0px;
  bottom: 0px;
  left: 0;
  width: 18rem;
  height: 100%;
  background-color: #2f2f2f;
  flex-direction: column;
  z-index: 1000;
}

.sidebar hr {
  border: 1px solid #fff;
  margin: -15px 0;
  order: 2;
}

/* Sidebar Header */
.sidebar_header {
  width: 100%;
  text-align: center;
  padding: 20px;
  order: 1;
  height: 20%;
  border-bottom: 1px solid rgb(139, 138, 138);

}

.sidebar_header img {
  height: 30px;
}

.sidebar_header_title {
  color: #fff;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

/* Sidebar Content	 */
.sidebar_content {
  word-wrap: break-word;
  height: 70%;
  display: flex;
  flex-direction: column;
  order: 3;
  color: #fff;
  padding-top: 0px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.sidebar_content h3 {
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
}

.sidebar_content ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #fff;
}

.sidebar_content li {
  margin-bottom: 5px;
  color: #fff;
  padding-left: 0;
  padding-right: 0;
}

.sidebar_content a {
  display: block;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  padding: 10px;
  width: 100%;
}

.sidebar_content button {
  display: block;
  font-size: 14px;
  color: #fff;
  background-color: transparent;
  padding: 10px;
  width: 100%;
  border: none;
  height: 40px;
  text-align: left;
}

.sidebar_content a:hover {
  background-color: #ddd;
  color: #000;
}

.fontawesome_icon {
  padding: 0px 10px 0px 5px;
}

.fi_margin {
  margin-left: 10px;
}

nav a.active {
  background-color: lightgray;
  color: #000;
}

/* Scrollbar customization */
.sidebar_content::-webkit-scrollbar {
  width: 0.5em;
  background-color: #b3b3b4;
  scrollbar-width: thin;
}

.sidebar_content::-webkit-scrollbar-thumb {
  background-color: #555;
  display: block;
  border-radius: 20px;
}


.sidebar_version {
  height: 10%;
  display: flex;
  flex-direction: column;
  order: 3;
  color: #fff;
  border-top: 1px solid rgb(139, 138, 138);
}