.container {
  background-color: #4fa2ae;
  width: 100%;
  height: 100vh;
  position: fixed;
  font-family: 'Open Sans', sans-serif;
  ;
}

.container button:disabled {
  background-color: #a5a7a8;
}

.loginContent {
  background-color: rgb(255, 255, 255);
  margin: auto;
  margin-top: 7%;
  height: 70%;
  width: 60%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-gap: 0;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.loginText {
  grid-area: 1 / 1 / span 4 / span 2;
  /* border: 1px solid black; */
  padding-top: 120px;
  background-color: #2f2f2f;
  color: white;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}

.imgContainer {
  text-align: center;
  /* border: 1px solid black; */
}

.textContainer {
  width: 80%;
  margin: auto;
  /* border: 1px solid black; */
}

.textContainer p {
  font-size: 20px;
  text-align: center;
}

.loginText img {
  width: 7rem;
  height: 7rem;
  /* border: 1px solid black; */
}

.loginForm {
  grid-area: 1 / 3 / span 4 / span 2;
  /* border: 1px solid black; */
  padding: 20px;
}

.loginFormText {
  margin-top: 35px;
}

.loginFormText p {
  font-size: 25px;
  font-weight: 500;
}

.inputContainer {
  margin-bottom: 10px;
}

.inputContainer label {
  font-size: 12px;
}

.forgotPassContainer {
  width: 100%;
  margin-bottom: 10px;
}

.forgotPassContainer p {
  font-size: 12px;
  /* float: right; */
  text-align: right;
}

.forgotPassContainer a {
  text-decoration: none;
}

.loginBtn {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #0077ff;
  color: white;
  border-radius: 0.5rem;
  margin-top: 5%;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  width: 100%;
}

.loginError {
  font-size: 12px;
  text-align: center;
  color: red;
}