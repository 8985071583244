.sidebar_vcontent {
  top: 0px;
}

.sidebar_vcontentP1 {
  font-size: 10px;
  height: 30%;
  width: 50%;
  position: absolute;
  padding-left: 20px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.sidebar_vcontentBar {
  font-size: 15px;
  height: 100%;
  width: 50%;
  float: right;
  margin-top: 12%;
}

.sidebar_vcontent img {
  height: 25px;
  margin-top: -20px;
}