.container {
  width: 98%;
  height: auto;
  margin: auto;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: .875rem;
  font-weight: 400;
  /* border: 2px solid rgb(218, 212, 212); */
  margin-top: 1rem;
  padding-bottom: 1rem;
}

.content_actions {
  width: 98%;
  height: 60px;
  margin: auto;
  padding-top: 15px;
}

.content_actions a {
  text-decoration: none;
  width: auto;
  padding: 0rem 1rem 0rem 1rem;
  color: black;
}

.content_actions a button {
  width: 100px;
  border-radius: 3px;
  font-size: 12px;
  padding: .3rem 1rem .3rem 1rem;
  border: none;
}


.content_actions_button {
  /* margin-left: auto;  */
  width: 100px;
  border-radius: 3px;
  background-color: rgb(127, 164, 182);
  color: white;
  font-size: 12px;
  border: 1px solid rgb(127, 164, 182);
  padding: .3rem 1rem .3rem 1rem;
}

.content {
  width: 98%;
  height: auto;
  border: 2px solid rgb(218, 212, 212);
  margin: auto;
  border-radius: 2px;
  padding: 1px 1px 1rem 1px;
}

.content_title {
  width: auto;
  height: 40px;
  background-color: #2f2f2f;
  color: white;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border: 2px solid rgb(218, 212, 212);
}

.content_info {
  display: inline-block;
  padding: 1rem 1rem;
  vertical-align: top;
  width: 50%;
  height: 50%;
}

.content_info_full {
  display: inline-block;
  padding: 1rem 1rem;
  vertical-align: top;
  width: 100%;
  height: 100%;
}

.content_title p {
  padding: 10px 0px 0px 8px;
}

.formChild {
  display: inline-block;
  padding: 1rem 1rem;
  vertical-align: middle;
  width: 50%;
}

.formChild em {
  color: red;
}



.formChild a span {
  margin-right: 3px;
}