.invoiceContainer {
  grid-area: main;
  width: 100%;
  margin: 0;
  /* border: 1px solid black; */
  padding: 20px;
  height: 100vh;
  background-color: #cfdcdd;
  /* overflow-y: scroll; */
}

.invoiceHeader {
  display: flex;
  justify-content: space-between;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
}

.invoiceTitle {
  margin: 0;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
}

.invoiceInfo {
  text-align: left;
  background-color: white;
  border: 1cm;
  border-color: black;
  box-shadow: 0px 0px 10px #888;
  /* border-radius: 20px; */
}

.invoiceContent {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.clientInfoMain {
  display: table-column;
  justify-content: space-between;
}

.clientInfo {
  width: 100%;
  background-color: white;
  /* border-radius: 20px; */
  border: 1cm;
  border-color: black;
  box-shadow: 0px 0px 10px #888;
}

.clientInfoText {
  margin-top: 5px;
  margin-left: 5px;
}

.clientTitle {
  /* margin: 0; */
  margin-left: 5px;
}

.invoiceDetails {
  width: 60%;
}

.detailsTitle {
  margin: 0;
}

.invoiceTable {
  width: 100%;
  border-collapse: collapse;
  border: 1cm;
  border-color: black;
  box-shadow: 0px 0px 10px #888;
}

.tableHeader {
  background-color: #16A2AF;
  text-align: left;
  padding: 8px;
}

.tableData {
  text-align: left;
  padding: 8px;
}

.invoiceFooter {
  margin-top: 20px;
  text-align: right;
}

.total {
  margin: 0;
}

.fa {
  display: inline;
}

.deleteInvoiceBtn {
  display: inline;
  width: 10%;
}

.invoiceTableHeading {
  background-color: darkcyan;
}

.invoiceInfoText {
  margin-left: 5px;
  margin-top: 5px;
}