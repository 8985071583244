.homeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-top: 50px;
  font-size: 36px;
}

.buttonsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 50px;
  width: 50%;
}

.button {
  border: 1px solid gray;
  border-radius: 20px;
  padding: 10px 20px;
  text-align: center;
  transition: all 0.2s;
  background-color: #2a67a0;
  color: white;
  font-weight: 800;
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.link {
  text-decoration: none;
}

@media (max-width: 600px) {
  .buttonsContainer {
    grid-template-columns: 1fr;
  }
}

.faContainer {
  font-size: 50px;
  color: white;
  margin-right: 0px 10px 0px 10px;
  /* display: none; */
}